<script lang="ts">
import Vue from "vue";
import { methods, getProps, getData, computed, mappedComputed, BlockPhoneInstance } from "./blockPhone";
import { mounted } from "./mounted";

import { Fragment } from "vue-frag";

import BlockInput from "@/components/shared/blocks/input.vue";
import PhoneNumberInput from "@/components/shared/blocks/user/phone/phoneNumberInput/phoneNumberInput.vue";
import RecoverAccount from "@/components/shared/auth/RecoverAccount.vue";

import { computes, validations } from "./helpers";

export default Vue.extend({
  name: "BlockPhone",
  components: { Fragment, BlockInput, RecoverAccount, PhoneNumberInput },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    blockState: {
      get(): boolean {
        return computed.blockState(this as any as BlockPhoneInstance);
      },
      set(blockState: boolean): void {
        computed.blockState(this as any as BlockPhoneInstance, blockState);
      },
    },
  },
  data() {
    return { ...getData(this) };
  },
  async mounted() {
    await mounted(this as any as BlockPhoneInstance);
  },
  methods: {
    async validations() {
      return validations(this as any as BlockPhoneInstance);
    },
    async computes() {
      return computes(this as any as BlockPhoneInstance);
    },

    async handleAccountRecovered() {
      return methods.handleAccountRecovered(this as any as BlockPhoneInstance);
    },
    phoneNumberInputChanged(
      phoneNumber: string,
      phoneNumberObject: {
        country: {
          dialCode: string;
          iso2: string;
          name: string;
        };

        countryCallingCode: string;
        countryCode: string;
        formatted: string;
        nationalNumber: string;
        number: string;
        valid: boolean;
      }
    ) {
      if (phoneNumberObject.valid) {
        this.internationalPhoneNumber = {
          number: phoneNumberObject.number,
          countryCode: phoneNumberObject.countryCode,
        };
        this.BlockInputs.phone.answer = phoneNumberObject.number;
      } else {
        this.internationalPhoneNumber = { number: "", countryCode: "" };
      }
    },
  },
});
</script>

<template>
  <Fragment>
    <!-- Recover Modal -->
    <RecoverAccount
      :userIdentifier="recover.userIdentifier"
      :claimId="$route.params.claimId"
      @authSuccessful="handleAccountRecovered()"
      uniqueModalId="Phone"
      @ready="$bvModal.show(`RecoverAccountPhone`)"
      :verifyOnly="false"
    />
    <!--  -->
    <template v-if="allowInternationalNumbers">
      <PhoneNumberInput
        @input="phoneNumberInputChanged"
        :preferredCountries="[
          'GB',
          'US',
          'CA',
          'AU',
          'DE',
          'FR',
          'IT',
          'ES',
          'NL',
          'BR',
          'MX',
          'ZA',
          'SE',
          'NO',
          'DK',
          'BE',
          'CH',
          'IE',
          'NZ',
        ]"
        :existingPhoneNumber="BlockInputs.phone.answer"
        :invalidFeedback="
          $te('blocks.contactDetails.' + BlockInputs.phone.invalidFeedback)
            ? $t('blocks.contactDetails.' + BlockInputs.phone.invalidFeedback)
            : BlockInputs.phone.invalidFeedback
        "
        :state="BlockInputs.phone.state"
        :label="BlockInputs.phone.label"
        :info="BlockInputs.phone.info"
        :padding="padding"
        :disabled="parentIsProcessing || BlockInputs.phone.disabled"
      />
    </template>
    <template v-else>
      <BlockInput
        v-bind="BlockInputs.phone"
        :padding="padding"
        v-bind.sync="BlockInputs.phone"
        :inputMode="'numeric'"
        :disabled="parentIsProcessing || BlockInputs.phone.disabled"
        :hintText="'We will only text you, we will never call you'"
        :hintIcon="'info-circle'"
        :sslBadge="true"
      />
    </template>
  </Fragment>
</template>
