import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { User } from "@claimsgate/core-types";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { InternationalPhoneNumber } from "./types";

export function getData(state: Vue) {
  return {
    user: {} as User,
    userData: undefined as User,
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    claimDataService: undefined as ClaimDataService,

    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    userId: null as string,
    infoModalService: new InfoModalService(state.$infoModal),
    uiToggles: {
      /** Flag to indicate if the user has already got a phone number set up correctly on their user */
      isPhoneNumberDefined: false,
      isLoading: false,
    },
    /**
     * Is true if userData email is defined but no CG consent on user Data OR
     * userData email is not defined, but firebaseAuth email is defined
     */
    isFirebaseMismatch: false,
    userAuthPhoneIsAlreadyDefined: false,
    BlockInputs: UserVariableBlocksService.getBlocks(["phone"]) as Record<"phone", BlockProps.Input<string>>,
    internationalPhoneNumber: { number: "", countryCode: "" } as InternationalPhoneNumber,

    uiMessages: {
      invalidPhoneNumber: "Please enter a valid UK phone number",
      invalidInternationalPhoneNumber: "Please enter a valid phone number",
      phoneAssociatedOtherAccount: "This phone number is associated with another account",
      nonUkMobileNumber: "We have been unable to validate your number as a valid UK mobile phone number.",
    },
    recoverAccountIsProcessing: false,
    recover: {
      userIdentifier: {
        phoneNumber: undefined,
      },
    },
  };
}
