<script lang="ts">
import { getInputDefaults } from "@/helpers/ClaimsGate/blocks/inputProps";
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { Props } from "@/types";
import Vue from "vue";
export default Vue.extend({
  name: "BlockVideo",
  components: {
    BlockPadding,
  },
  props: {
    embedLink: {
      type: String,
      required: true,
      description: "The full YouTube embed link",
      default: "https://www.youtube.com/embed/MZfDqxzcqwk",
    } as Props.Str,
    ...(getInputDefaults(["padding"]) as { padding: Props.Str }),
  },
  computed: {
    link(): string {
      return BuilderUtility.parseUrl(this.embedLink + "?loop=1&rel=0");
    },
  },
});
</script>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <div class="embed-responsive embed-responsive-16by9">
      <iframe
        :src="link"
        title="video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="w-100"
      ></iframe>
    </div>
  </div>
</template>
