<script lang="ts">
import BlockPadding from "@/components/shared/blocks/padding.vue";
import Vue from "vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
// {field: 'nationalInsuranceNumber', group: 'user', id: 'IqZ7XgCCfqLU12JxyqLX', type: 'string'}

let inputPropsValue: Props.BlockInput = inputProps({ answerType: "string", extraProps: ["inputType"] });
delete inputPropsValue.label;
delete inputPropsValue.info;
delete inputPropsValue.required;
delete inputPropsValue.disabled;

export default Vue.extend({
  name: "BlockNationalInsurance",
  components: {
    BlockPadding,
  },
  props: {
    ...inputPropsValue,
  },
  data() {
    return {
      newAnswer: "",
      disabled: false,
    };
  },
  watch: {
    newAnswer: function () {
      this.$emit("update:answer", this.newAnswer.toUpperCase());
    },
  },
  mounted() {
    if (this.answer) {
      //@ts-ignore
      this.disabled = true;
    }
    this.newAnswer = this.answer ?? "";
  },
  computed: {
    ...themesComputed,
  },
});
</script>

<template>
  <div>
    <BlockPadding :padding="padding" />
    <h5 class="mb-0 text-left">National Insurance Number</h5>
    <!-- <p class="mb-0 text-left">
      {{ info }}
    </p> -->
    <b-form-input
      v-model="newAnswer"
      :type="inputType"
      :size="theme.size"
      :state="state"
      :squared="theme.squared"
      :disabled="disabled"
      placeholder="AA 12 34 56 A"
      v-mask="'AA ## ## ## A'"
      :class="{ disabled: disabled ? 'disabled' : '' }"
      style="text-transform: uppercase"
      @update="$emit('update:state', null)"
    />
    <p v-if="state === false && invalidFeedback.length > 0" class="mb-0">
      <b-form-invalid-feedback :state="state">
        {{ invalidFeedback }}
      </b-form-invalid-feedback>
    </p>
  </div>
</template>
