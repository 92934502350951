import { userIsAddressVerified, userIsIdVerified } from "@/helpers/ClaimsGate/verification";
import { identificationFlow } from "@claimsgate/core";
import { BlockYotiInstance } from "../BlockYotiInstance";

/**
 * Calculate the id flow the user needs based on any exising veirfications.
 * Function assumes that user is missing one or both of id and address verification
 */
export function computeIdFlow(state: BlockYotiInstance): identificationFlow {
  const { userData } = state;

  const funnelIdFlow = state.identificationFlow;

  console.log("Computing Id flow with", { verifications: userData.verifications, funnelIdFlow });
  if (
    funnelIdFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback ||
    funnelIdFlow === identificationFlow.yotiIdAndAddress
  ) {
    // The user must be missing either id, address, or both  verifications.
    return userIsIdVerified(userData)
      ? identificationFlow.yotiAddress
      : userIsAddressVerified(userData)
      ? identificationFlow.yotiId
      : funnelIdFlow;
  }

  return funnelIdFlow;
}
