import { getFirebaseBackend } from "@/authUtils";
import { BlockPhoneInstance } from "./blockPhone";
import { User } from "@claimsgate/core-types";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getClaimDataService, getUserId } from "@/helpers/vue";

export async function mounted(state: BlockPhoneInstance) {
  const userId = getUserId();

  if (!userId) {
    return;
  }

  state.userId = getUserId();

  state.userDataService = new UserDataService(state.userService.getUserId());

  getClaimDataService<BlockPhoneInstance>(state);

  //await state.userDataService.refresh();

  calculateIsPhoneNumberAlreadyDefined(state);
}

function calculateIsPhoneNumberAlreadyDefined(state: BlockPhoneInstance) {
  const user: User = state.userDataService.getCache();
  const authUser = getFirebaseBackend().firebaseAuth()?.currentUser;

  state.uiToggles.isPhoneNumberDefined =
    user.phoneNumber && authUser.phoneNumber && user.phoneNumber === authUser.phoneNumber;

  if (state.uiToggles.isPhoneNumberDefined) {
    state.BlockInputs.phone.disabled = true;
    state.BlockInputs.phone.answer = user.phoneNumber;
    return;
  }

  if (user?.phoneNumber) {
    state.BlockInputs.phone.answer = user.phoneNumber;
  }
}
