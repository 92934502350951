<script lang="ts">
import Vue from "vue";

//@ts-check
// eslint-disable-next-line no-unused-vars
import { Props, BlockProps } from "@/types";
import { eventComputed, formComputed, permissionsComputed } from "@/state/helpers";

// Components
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";

// Services
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { WorkspaceService } from "@claimsgate/core";

// eslint-disable-next-line no-unused-vars
import { getUserHelper, UserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { getFirebaseBackend } from "@/authUtils";

export default Vue.extend({
  components: {
    BlockInput,
    BlockButton,
    BlockCheckbox,
    BlockPadding,
  },
  props: {
    answer: {
      type: String,
      required: false,
      default: "",
      allowVariable: false,
      description: "The text in the input",
    } as Props.Answer,
  },
  data() {
    return {
      /**@type { {headingText: BlockProps.Text, workspaceName: BlockProps.Input, termsAndConditionsAccepted: BlockProps.Checkbox, createWorkspace: BlockProps.Button } } */
      BlockInputs: UserVariableService.getWorkspaceBlocks(),
      userService: getUserHelper() as UserHelper,
      userVariableService: new UserVariableService(),
      /**@type { any } */
      userData: undefined,
      infoModalService: new InfoModalService(this.$infoModal),
      workspaceName: "",
      headingText: "",
      termsAndConditionsAccepted: "not_accepted",
      /**@type { WorkspaceService } */
      workspaceService: new WorkspaceService(getFirebaseBackend().firebase()),
      /**@type { UserDataService } */
      userDataService: null,
      /**@type { { pageLoading : boolean, showWorkspaceCreate: boolean} } */
      uiToggles: {
        pageLoading: true,
        showWorkspaceCreate: true,
      },
      /**@type { RegExp } */
      spChars: /[!@#$%^&*()_+\-={};':"|,.<>?]+/,
      /**@type {{
       * noWorkspaceNameGiven: string,
       * workspaceNameTooLong: string,
       * workspaceNameIncludesSpecialChars: string,
       * termsAndConditionsNotAccepted: string,
       * verifyFailed: 'We were unable to verify your phone number, please check it and try again',
       * required: 'This field is required'
       * }} */
      uiMessages: {
        noWorkspaceNameGiven: "Please provide a workspace name",
        workspaceNameTooLong: "Workspace name too long. Maximum 30 characters",
        workspaceNameIncludesSpecialChars: "Workspace name can not include special characters such as / & # $ . , [ ?",
        termsAndConditionsNotAccepted: "Please read and accept Privacy Policy in order to create a Workspace",
        verifyFailed: "We were unable to verify your phone number, please check it and try again",
        required: "This field is required",
      },
    };
  },

  methods: {
    /**
     * Toggles a UI Toggle with mutual exclsuivity
     */
    uiToggle(element) {
      for (const key of Object.keys(this.uiToggles)) {
        if (element === key) {
          this.$nextTick(() => {
            this.uiToggles[key] = true;
          });
        } else {
          this.uiToggles[key] = false;
        }
      }
    },

    /**
     * Emits an event to form.vue to submit the page
     */
    emitSubmit() {
      this.$store.dispatch("events/fire", { name: this.eventValues.next });
    },
    /**
     * Emits an event to form.vue to refersh its data service caches
     */
    emitRefresh() {
      window.console.log("Emitting refresh from workspace.vue");
      this.$store.dispatch("events/fire", { name: this.eventValues.refresh });
    },

    async createWorkspace() {
      this.BlockInputs.createWorkspace.isProcessing = true;

      // check workspace name has been provided
      if (!this.workspaceName) {
        this.BlockInputs.workspaceName.state = false;
        this.BlockInputs.workspaceName.invalidFeedback = this.uiMessages.noWorkspaceNameGiven;
        this.BlockInputs.createWorkspace.isProcessing = false;
        return;
      }

      // check character limit
      if (this.workspaceName.length > 30) {
        this.BlockInputs.workspaceName.state = false;
        this.BlockInputs.workspaceName.invalidFeedback = this.uiMessages.workspaceNameTooLong;
        this.BlockInputs.createWorkspace.isProcessing = false;
        return;
      }

      // check for special characters
      if (this.spChars.test(this.workspaceName)) {
        this.BlockInputs.workspaceName.state = false;
        this.BlockInputs.workspaceName.invalidFeedback = this.uiMessages.workspaceNameIncludesSpecialChars;
        this.BlockInputs.createWorkspace.isProcessing = false;
        return;
      }

      // check terms and conditions have been accepted
      if (this.termsAndConditionsAccepted !== "accepted") {
        this.BlockInputs.termsAndConditionsAccepted.state = false;
        this.BlockInputs.termsAndConditionsAccepted.invalidFeedback = this.uiMessages.termsAndConditionsNotAccepted;
        this.BlockInputs.createWorkspace.isProcessing = false;
        return;
      }

      // Create Workspace & Add the authenticated user as a member of the workspace & Grant them all permissions
      const createWorkspace = getFirebaseBackend().firebaseFunctions().httpsCallable("createWorkspace");

      // ? return value is enveloped in extra object layer
      const { data: workspaceId } = await createWorkspace({ name: this.workspaceName });

      // Select the created workspace using Vue X
      await this.$store.dispatch("permissions/setWorkspaceId", workspaceId.data);
      await this.$store.dispatch("permissions/setWorkspace", workspaceId.data);
      await this.$store.dispatch("permissions/setUserApps", {
        workspaceId: workspaceId.data,
        userId: this.userService.getUserId(),
      });

      this.BlockInputs.createWorkspace.isProcessing = false;

      // ? This refreshes our cache and looses the funnel variables - probably deprecated
      // this.emitRefresh(); // This triggers a refresh event to run?

      this.$nextTick(() => {
        // save asnwer to a funnel variable
        this.$emit("update:answer", this.workspaceName);
      });

      this.$nextTick(() => {
        this.emitSubmit();
      });
    },
  },

  mounted() {
    if (this.userService.getUserId()) {
      this.userDataService = new UserDataService(this.userService.getUserId());
    }
  },
  computed: {
    ...formComputed,
    ...eventComputed,
    ...permissionsComputed,
  },
});
</script>
<template>
  <div>
    <template v-if="uiToggles.showWorkspaceCreate">
      <BlockPadding :padding="'10'" />
      <BlockInput
        v-bind="BlockInputs.workspaceName"
        v-bind.sync="BlockInputs.workspaceName"
        :answer.sync="workspaceName"
      />
      <BlockCheckbox
        v-bind="BlockInputs.termsAndConditionsAccepted"
        v-bind.sync="BlockInputs.termsAndConditionsAccepted"
        :answer.sync="termsAndConditionsAccepted"
      />
      <BlockButton
        text="Create"
        :block="false"
        v-bind="BlockInputs.createWorkspace"
        v-bind.sync="BlockInputs.createWorkspace"
        @clicked="createWorkspace"
      />
    </template>
  </div>
</template>
