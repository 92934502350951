import { BlockYotiProps } from "./BlockYotiInstance";
import { Props } from "@/types";
import { identificationFlow } from "@claimsgate/core";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
export enum RequireAge {
  none = "None",
  collect = "Collect",
  over18 = "Over 18",
}
export const blockYotiPropsForParent = {
  faceMatch: {
    type: Boolean,
    default: true,
    description:
      "When true the user will be asked to scan their face by yoti, to check that they are a match for the prodivded ID",
  } as Props.Bool,
};
const blockYotiProps = {
  ...blockYotiPropsForParent,
  livenessCheck: {
    type: Boolean,
    default: true,
    description:
      "When true the user will be asked to scan their face by yoti, to confrim that the user is a real human",
  } as Props.Bool,
  identificationFlow: {
    type: String,
    description: "Determine what methods will be used to identify the user",
    default: identificationFlow.yotiId,
    valid: [
      identificationFlow.yotiAddress,
      identificationFlow.yotiIdAndAddress,
      identificationFlow.yotiId,
      identificationFlow.yotiIdAndAml,
    ] as Array<identificationFlow>,
  } as Props.Str,
  padding: { ...(inputProps({ excludeDefaults: true, extraProps: ["padding"] }) as Props.Str) },
  //requireAge: {
  //  type: String,
  //  description: `Collect the users age, and optionaly only allow submission from over 18's`,
  //  valid: [RequireAge.none, RequireAge.collect, RequireAge.over18],
  //  default: RequireAge.none,
  //} as Props.Str,
  isBuilder: {
    type: Boolean,
    default: false,
    description: `Lets the block know if it is in builder, so it doesn't launch a session`,
  } as Props.Bool,
  isChild: {
    type: Boolean,
    default: false,
    description: "",
  } as Props.Bool,
  allowManualUploadFallback: {
    type: Boolean,
    default: false,
    description: `If true, the user will be able to manually upload their ID if Yoti fails`,
  } as Props.Bool,
};
export function getProps() {
  return {
    ...blockYotiProps,
  };
}
