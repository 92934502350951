import { getFirebaseBackend } from "@/authUtils";
import { BlockPhoneInstance } from "../blockPhone";
import { captureMessage } from "@sentry/vue";
import * as Sentry from "@sentry/vue";

export function firePhoneValidationFailedEvent(state: BlockPhoneInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("phoneNumber", state.BlockInputs.phone.answer);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("PhoneValidationFailed");
  });
}
export function firePhoneValidationPassedEvent(state: BlockPhoneInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("phoneNumber", state.BlockInputs.phone.answer);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("PhoneValidationPassed");
  });
}
export function firePhoneValidationStartedEvent() {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("PhoneValidationStarted");
  });
}
