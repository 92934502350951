import {
  userIsAddressVerified,
  userIsIdVerified,
  userIsFaceMatched,
  userIsElectoralRollVerified,
} from "@/helpers/ClaimsGate/verification";
import { eventComputed, formComputed, themesComputed } from "@/state/helpers";
import { identificationFlow } from "@claimsgate/core";
import { BlockYotiInstance } from "./BlockYotiInstance";

export function mappedComputed() {
  return {
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(themesComputed as Record<keyof typeof themesComputed, any>),
    ...(formComputed as Record<keyof typeof formComputed, any>),
  };
}

export const computed = {
  isVerified,
  showContactFaq,
};

function isVerified(state: BlockYotiInstance) {
  if (
    state.identificationFlow === identificationFlow.yotiId ||
    state.identificationFlow === identificationFlow.electoralRollAndYotiId
  ) {
    if (
      state.userData?.verifications?.findIndex((v) => !!v.manuallyVerifiedBy && v.types[0] === "Identity Upload") > -1
    )
      return true;

    if (state.faceMatch) {
      return userIsIdVerified(state.userData) && userIsFaceMatched(state.userData);
    } else {
      return userIsIdVerified(state.userData);
    }
  }
  //Added
  if (state.identificationFlow === identificationFlow.electoralRoll) {
    return userIsElectoralRollVerified(state.userData);
  }
  if (
    state.identificationFlow === identificationFlow.yotiIdAndAddress ||
    state.identificationFlow === identificationFlow.electoralRollWithYotiIdAndAddressFallback
  ) {
    return userIsIdVerified(state.userData) && userIsAddressVerified(state.userData);
  }
  if (state.identificationFlow === identificationFlow.yotiAddress) {
    return userIsAddressVerified(state.userData);
  }

  if (state.identificationFlow === identificationFlow.yotiIdAndAml) {
    const hasBeenManuallyIdVerified =
      state.userData?.verifications?.findIndex((v) => !!v.manuallyVerifiedBy && v.types[0] === "Identity Upload") > -1;

    //Commented out to prevent regression error. Not currently needed at this time.
    // const hasBeenManuallyAmlVerified =
    //   state.userData?.verifications?.findIndex(
    //     (v) => !!v.manuallyVerifiedBy && v.types[0] === "Anti Money Laundering"
    //   ) > -1;

    if (hasBeenManuallyIdVerified) return true;
    return userIsIdVerified(state.userData);
  }
  // Fallback for users that have been manually verified
  if (state.userData?.verifications?.findIndex((v) => !!v.manuallyVerifiedBy) > -1) return true;

  return !!state.userData?.identityPicture1;
}

function showContactFaq(state: BlockYotiInstance): boolean {
  if (state.funnelMetaCache?.correspondenceEmail && state.userData && state.claimId) return true;
  else return false;
}
