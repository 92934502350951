import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
import { BlockInstanceProps, Props } from "@/types";

export { methods };

export { getProps, getData, computed, mappedComputed };

export type Data = ReturnType<typeof getData>;
export type Computed = typeof computed & ReturnType<typeof mappedComputed>;

/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockEmailInstance` as its type
 */
export type BlockPhoneInstance = Vue & Data & Computed & BlockInstanceProps<Props.BlockPhone>;
