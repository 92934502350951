import { Claim, User } from "@claimsgate/core-types";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { YotiService } from "@/helpers/ClaimsGate/yoti/YotiService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { FunnelsService } from "@claimsgate/core";
import firebase from "firebase/compat/app";
import { getFirebaseBackend } from "@/authUtils";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { claimsGateTextPropValues } from "@/helpers/ClaimsGate/blocks/textProps";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";
import { DocumentReference } from "@claimsgate/core/src/services/firebase/types";
import { i18n } from "@/i18n";
export function getData(state: Vue) {
  return {
    yotiSrc: undefined as string,
    // event: undefined,
    userData: undefined as User,
    claimData: undefined as Claim,
    userId: undefined as string,

    claimId: state.$route.params.claimId as string,
    funnelId: state.$route.params.funnelId as string,
    identityPending: undefined as boolean,
    identitySubmitted: undefined as boolean,
    // Helper classes
    yotiService: new YotiService(),
    userDataService: undefined as UserDataService,
    hasCameraPermission: null as boolean,
    isChecked: false,

    currentYotiSessionId: undefined as string,
    previousYotiSessionId: undefined as string,

    userService: getUserHelper(),
    variableService: new VariableService(),
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    claimDataService: undefined as ClaimDataService,
    unsubscribeFromUserSnap: undefined as ReturnType<DocumentReference["onSnapshot"]>,
    loadingYotiSrc: false,
    infoModal: new InfoModalService(state.$infoModal),
    paragraphProps: claimsGateTextPropValues.paragraph,
    subTitleProps: claimsGateTextPropValues.title,
    BlockInputs: {
      submitButton: { ...UserVariableBlocksService.getBlocks(["nextBlockButton"]).nextBlockButton, submitButtonDefault: false } as BlockProps.Button,
    },
    isDestroyed: false,
    userHadAttemptedVerificationBeforeMount: false,
    faqContent: faqContent,
    faqTitle: faqTitle,
    contactFaqShowing: false,
    showManual: false,
  };
}
const faqTitle = i18n.t("blocks.yoti.faqs.title") as string;
const faqContent: Array<{
  faqQuestion: string;
  faqAnswer: string;
  displayInACard: boolean;
  isHtmlAnswer: boolean;
}> = [
    {
      faqQuestion: i18n.t("blocks.yoti.faqs.troubleCamera") as string,
      faqAnswer: `
      <div class="mt-3">
  <h4 class="font-weight-semibold text-md text-grey-900">${i18n.t("blocks.yoti.faqs.iphoneUsersTitle")}</h4>
  <p class="text-sm mb-0">
    <span class="text-grey-600 font-weight-semibold">${i18n.t("blocks.yoti.faqs.iphoneSafariInstructions")}</span>
  </p>
  <p class="text-sm mt-2">
    <span class="text-grey-600 font-weight-semibold">${i18n.t(
        "blocks.yoti.faqs.iphoneOtherBrowsersInstructions"
      )}</span>
  </p>
</div>
<!-- Android Section -->
<div class="mt-4">
  <h4 class="font-weight-bold text-md text-grey-900">${i18n.t("blocks.yoti.faqs.androidUsersTitle")}</h4>
  <p class="text-sm mb-0">${i18n.t("blocks.yoti.faqs.androidPopupInstructions")}</p>
  <p class="text-sm mt-2">
    ${i18n.t("blocks.yoti.faqs.androidManualInstructions")}
  </p>
</div>

<!-- Desktop/Laptop Section -->
<div class="mt-4">
  <h4 class="font-weight-bold text-md text-grey-900">${i18n.t("blocks.yoti.faqs.desktopUsersTitle")}</h4>

  <p class="text-sm mb-0 mt-2">
    <span class="font-weight-semibold text-grey-600">${i18n.t("blocks.yoti.faqs.chromeInstructions")}</span>
  </p>

  <p class="text-sm mb-0 mt-2">
    <span class="font-weight-semibold text-grey-600">${i18n.t("blocks.yoti.faqs.firefoxInstructions")}</span>
  </p>

  <p class="text-sm mb-0 mt-2">
    <span class="font-weight-semibold text-grey-600">${i18n.t("blocks.yoti.faqs.edgeInstructions")}</span>
  </p>

  <p class="text-sm mb-0 mt-2">
    <span class="font-weight-semibold text-grey-600">${i18n.t("blocks.yoti.faqs.safariInstructions")}</span>
  </p>
</div>

    `,
      displayInACard: true,
      isHtmlAnswer: true,
    },

    {
      faqQuestion: i18n.t("blocks.yoti.faqs.whatDocuments") as string,
      faqAnswer: `<p>${i18n.t("blocks.yoti.faqs.introTextDocs")}</p>
    <ul>
      <li>${i18n.t(
        "blocks.yoti.faqs.passportItem"
      )} - <a target="_blank" href="https://yoti.force.com/yotisupport/s/article/What-passports-does-Yoti-accept">click here</a></li>
      <li>${i18n.t(
        "blocks.yoti.faqs.drivingLicenseItem"
      )} - <a target="_blank" href="https://yoti.force.com/yotisupport/s/article/What-driving-licences-does-Yoti-accept">click here to review list of countries</a></li>
      <li>${i18n.t(
        "blocks.yoti.faqs.nationalIdItem"
      )} - <a target="_blank" href="https://yoti.force.com/yotisupport/s/article/What-national-identity-cards-does-Yoti-accept">click here</a> to review list of countries</li>
      <li>${i18n.t("blocks.yoti.faqs.residencePermitItem")}</li>
    </ul>
    <p>${i18n.t("blocks.yoti.faqs.additionalInfo")}</p>`,
      displayInACard: true,
      isHtmlAnswer: true,
    },
    {
      faqQuestion: i18n.t("blocks.yoti.faqs.howToTakePhoto") as string,
      faqAnswer: `<p>${i18n.t("blocks.yoti.faqs.introTextTakePhoto")}</p>
    <ul>
      <li>${i18n.t("blocks.yoti.faqs.visibleCorners")}</li>
      <li>${i18n.t("blocks.yoti.faqs.noFingerCovering")}</li>
      <li>${i18n.t("blocks.yoti.faqs.documentClarity")}</li>
      <li>${i18n.t("blocks.yoti.faqs.adequateLighting")}</li>
      <li>${i18n.t("blocks.yoti.faqs.clearImage")}</li>
      <li>${i18n.t("blocks.yoti.faqs.avoidGlare")}</li>
      <li>${i18n.t("blocks.yoti.faqs.fullDocumentImage")}</li>
    </ul>`,
      displayInACard: true,
      isHtmlAnswer: true,
    },
  ];
