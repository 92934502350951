import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { BlockPhoneInstance } from "../blockPhone";

/** Saves a phone number on the user in Firebase Authentication and updates the UserDataService  */
export async function savePhoneNumber(state: BlockPhoneInstance, phoneNumber: string): Promise<boolean> {
  // @ts-ignore
  const { data: setUserResult, error, exception } = await UserVariableService.setUserContactDetails(phoneNumber);

  // If there was an issue setting the user's phone number then it must be invalid
  // since we have previously checked if the user exists
  if (!setUserResult || !setUserResult?.status || error || exception) {
    return false;
  }

  state.userDataService.setArtefact("phoneNumber", phoneNumber);
  if (state.allowInternationalNumbers) {
    state.userDataService.setArtefact("phoneNumberCountryCode", state.internationalPhoneNumber.countryCode);
  } else {
    state.userDataService.setArtefact("phoneNumberCountryCode", "GB");
  }

  return true;
}
