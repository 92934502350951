import { getFirebaseBackend } from "@/authUtils";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { BlockPhoneInstance } from "../blockPhone";
import { askUserToLogin } from "./askUserToLogin";

/** Checks if a given phone number is already in use by another user */
export async function isPhoneNumberAvailable(state: BlockPhoneInstance, phoneNumber: string): Promise<boolean> {
  // @ts-ignore
  const { data: userExistsResult, exception } = await UserVariableService.checkIfUserExists(undefined, phoneNumber);

  if (exception || !userExistsResult) {
    throw new Error(exception ?? userExistsResult);
  }

  if (userExistsResult.status && userExistsResult.user.phoneNumber) {
    // if (state.recoverAccountIsProcessing) return false;
    // The user has entered a phone number which is different than the one currently assoicated
    // with their Firebase Authentication User
    if (getFirebaseBackend().firebaseAuth().currentUser.phoneNumber !== state.BlockInputs.phone.answer) {
      state.recoverAccountIsProcessing = true;
      askUserToLogin(state, userExistsResult);

      return false;
    }
  }

  return true;
}
